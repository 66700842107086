import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import "../style/Home.scss";

function Home() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [lang,] = useState(
    searchParams.get("lang") ||"pt"
  );

  const startDate = new Date('2023-12-12');
  const currentDate = new Date();
  const timeDifference = currentDate - startDate;
  const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 60 * 24));

  const [prices, setPrices] = useState();

  const [countCustomer, setCountCustomer] = useState(0);
  const [countValue, setCountValue] = useState(0);

  const [targetValueCount, setTargetValueCount] = useState(process.env.REACT_APP_COUNT_MILLIONS_SOLD || 153);
  const [targetCustomerCount, setTargetCustomerCount] = useState(process.env.REACT_APP_COUNT_CUSTOMERS || 3658);

  /*useEffect(() => {
    const valueAddedPerDay = 0.2 * daysPassed;

    const newValueCount = targetValueCount + valueAddedPerDay;
    setTargetValueCount(newValueCount);
  }, []);

  useEffect(() => {
    const customerAddedPerDay = 5 * daysPassed;

    const newCustomerCount = targetCustomerCount + customerAddedPerDay;
    setTargetCustomerCount(newCustomerCount);
  }, []);*/

  useEffect(() => {
    const interval = setInterval(() => {
      setCountCustomer((prevCounter) => {
        if (prevCounter < targetCustomerCount - 12) {
          return prevCounter + 12;
        }
        if (prevCounter !== targetCustomerCount && prevCounter < targetCustomerCount) {
          return prevCounter + 1;
        }
        clearInterval(interval);
        return prevCounter;
      });
    }, 0.01);
    return () => clearInterval(interval);
  }, [targetCustomerCount]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountValue((prevCounter) => {
        if (prevCounter < targetValueCount) {
          return prevCounter + 1;
        } else if (prevCounter !== targetValueCount && prevCounter < targetValueCount) {
          return prevCounter + 1;
        }
        clearInterval(interval);
        return prevCounter;
      });
    }, 0.01);
    return () => clearInterval(interval);
  }, [targetValueCount]);

  const getPriceForSymbol = (symbol) => {
    try {
      const entry = prices.find((entry) => entry.symbol === symbol);
      return entry ? entry.price : 'N/A';
    } catch {
      console.info('waiting...')
    }

  };

  useEffect(() => {


    const symbols = [
      "USDTBRL",
      "BTCBRL",
      "ETHBRL",
      "BNBBRL",
      "BTCUSDT"
    ];

    async function fetchData() {
      try {
        const updatedPrices = await Promise.all(
          symbols.map(async (symbol) => {
            const url = `https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`;
            const res = await axios.get(url);
            return { symbol, price: res.data.price };
          })
        );

        setPrices(updatedPrices);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    // Fetch data initially
    fetchData();

    // Set up interval to fetch data every 5 seconds (adjust as needed)
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const goToContact = () => {
    searchParams.set("direction", "contact");
    setSearchParams(searchParams);
    window.location.href = window.location.href;
  };

  const showAbout = () => {
    const detailsSection = document.getElementById("about");
    if (detailsSection) {
      const yOffset =
        detailsSection.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  var currentDirection = searchParams.get("direction") || "home";

  if (currentDirection === "about") {
    showAbout();
  }

  return (
    <div>
      <div
        className="welcome"
        id="welcome"
      >
        <div className="side">
          <div className="left">
          </div>
          <div className="right">

            {lang === "pt" ? <h2>
              Negociar Criptoativos<br />
              com a Garden<br />
              é simples!
            </h2> : null}
            {lang === "es" ? <h2>
              Negociar Criptoactivos<br />con nosotros<br />es fácil
            </h2> : null}
            {lang === "en" ? <h2>
              Trading Cryptoassets<br />with us<br />is easy!
            </h2> : null}

            {lang === "pt" ? <p>Somos uma assessoria especializada em compra e venda de criptomoedas.<br /><br />Garantir segurança e tranquilidade aos nossos clientes, é nosso compromisso.</p> : null}
            {lang === "es" ? <p>Somos una consultora especializada en compra y venta de criptomonedas.<br /><br />Garantizar la seguridad y tranquilidad de nuestros clientes es nuestro compromiso.</p> : null}
            {lang === "en" ? <p>We are a consultancy specialized in buying and selling cryptocurrencies.<br /><br />Ensuring safety and peace of mind for our customers is our commitment.</p> : null}

            <div className="welcome-buttons">
              <button id="primary" onClick={showAbout}>
                {lang === "pt" ? "Saiba Mais" : null}
                {lang === "es" ? "Saber más" : null}
                {lang === "en" ? "Learn About" : null}
              </button>
            </div>

          </div>

        </div>
      </div>

      <div className="prices">

        <div className="price">
          <p className="title">
            USDT - BRL
          </p>

          <p className="value">
            R$ {parseFloat(getPriceForSymbol('USDTBRL')).toFixed(2)}
          </p>
        </div>

        <div className="price">
          <p className="title">
            BTC - BRL
          </p>

          <p className="value">
            R$ {parseFloat(getPriceForSymbol('BTCBRL')).toFixed(2)}
          </p>
        </div>

        <div className="price">
          <p className="title">
            ETH - BRL
          </p>

          <p className="value">
            R$ {parseFloat(getPriceForSymbol('ETHBRL')).toFixed(2)}
          </p>
        </div>

        <div className="price">
          <p className="title">
            BNB - BRL
          </p>

          <p className="value">
            R$ {parseFloat(getPriceForSymbol('BNBBRL')).toFixed(2)}
          </p>
        </div>

        <div className="price">
          <p className="title">
            BTC - USDT
          </p>

          <p className="value">
            ₮ {parseFloat(getPriceForSymbol('BTCUSDT')).toFixed(3)}
          </p>
        </div>

      </div>

      <div
        className="about"
        id="about"
      >
        <div className="placeholder">
          <h1>

            {lang === "pt" ? "Na Garden você encontra..." : null}
            {lang === "es" ? "Con la ayuda de Garden encontrarás..." : null}
            {lang === "en" ? "With Garden assitance you will find..." : null}

          </h1>

          <div className="sell-buy">

            <img
              src={process.env.PUBLIC_URL + "/sell-buy.webp"}
              alt="sell-buy"
              className="about-icons"
            />

            {lang === "pt" ? <p>Compra e Venda<br />de Ativos<br />Sem Complicações</p> : null}
            {lang === "es" ? <p>Compra y Venta<br />de Activos<br />Sin Complicaciones</p> : null}
            {lang === "en" ? <p>Buy and Sell<br />Crypto<br />Without Struggling</p> : null}

          </div>

          <div className="docs">

            <img
              src={process.env.PUBLIC_URL + "/docs.webp"}
              alt="docs"
              className="about-icons"
            />

            {lang === "pt" ? <p>
              Transações Seguras e
              <br />Privacidade
            </p> : null}
            {lang === "es" ? <p>
              Transacciones Seguras y
              <br />Privacidad
            </p> : null}
            {lang === "en" ? <p>
              Safety Transactions and
              <br />Privacy
            </p> : null}
          </div>


          <div className="certified">

            <img
              src={process.env.PUBLIC_URL + "/certified.webp"}
              alt="certified"
              className="about-icons"
            />
            {lang === "pt" ? <p>
              Acesso a Condições<br />Diferenciadas de Preço
              <br />e Especialistas no Assunto
            </p> : null}
            {lang === "es" ? <p>
              Acceso a<br />Precios Exclusivos
              <br />y Expertos para Ayudarte
            </p>: null}
            {lang === "en" ?  <p>
              Exclusive Prices
              <br />and Experts to Help You
            </p> : null}

          </div>
        </div>
      </div>

      <div className="placeholder">
      {lang === "pt" ? <h1>
          Segurança e Confiança<br />São o que mantêm esses números crescendo!
        </h1>: null}
            {lang === "es" ? <h1>
              Seguridad y Confianza<br />Es lo que hace que esos números sigan creciendo
        </h1>: null}
            {lang === "en" ? <h1>
           Security and Trust<br />It's what keeps these numbers growing!
        </h1> : null}

        <div className="counter">


          <div className="child">
            <p>
            {lang === "pt" ? "Clientes Consolidados": null}
            {lang === "es" ? "Clientes Consolidados": null}
            {lang === "en" ? "Customers": null}
              
            </p>
            <h1>
              +{countCustomer}
            </h1>

          </div>

          <div className="child">
            <p>
            {lang === "pt" ? "Milhões Negociados": null}
            {lang === "es" ? "Millones negociados": null}
            {lang === "en" ? "Millions Traded": null}
              
            </p>
            <h1>
              +{countValue}M
            </h1>

          </div>

        </div>
      </div>

      <div className="placeholder">
        
        {lang === "pt" ? <h1>Não Perca Tempo!<br /></h1>: null}
            {lang === "es" ? <h1>No te demores!<br /></h1>: null}
            {lang === "en" ? <h1>Don't Delay!<br /></h1>: null}
       
        {lang === "pt" ?  <h2>Fale já com um especialista!<br /></h2>: null}
            {lang === "es" ?  <h2>Habla con uno de nuestros especialistas ahora!<br /></h2>: null}
            {lang === "en" ?  <h2>Talk with our team now!<br /></h2>: null}

        <br /><br /><br />

        <button onClick={goToContact} id="primary">

        {lang === "pt" ?  "Falar com Especialista": null}
            {lang === "es" ?  "Saber Mas": null}
            {lang === "en" ?  "Learn More": null}
          
        </button>
        <br /><br /><br />
      </div>

    </div>
  );
}

export default Home;
