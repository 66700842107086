import React from 'react'

export default function Whatsapp() {
  return (
    <a className='wpp-logo' href='https://api.whatsapp.com/send/?phone=%2B5517981471699&text=Gostaria%20de%20tirar%20uma%20dúvida&type=phone_number&app_absent=0'>
          <img
            src={process.env.PUBLIC_URL + "/icons/whatsapp.webp"}
            className="wpp-logo"
            alt="wpplogo"
          />
    </a>
  )
}
