import { useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

function LawEnforcement() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [lang,] = useState(
    searchParams.get("lang") || "pt"
  );
  
  const [shouldShake, setShouldShake] = useState(false);

  const [name, setName] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [mail, setMail] = useState();

  async function SendMessage() {
    if (!name || !mail || !subject || !message) {
      // Apply shake effect to fields that are empty
      setShouldShake(true);
      return;
    }

    // Reset the shake effect when the form is successfully submitted
    setShouldShake(false);

    const cleanForm = () => {
      setName('');
      setMail('');
      setSubject('');
      setMessage('');
      setShouldShake(false);

      const nameInput = document.getElementById('nome');
      if (nameInput) {
        nameInput.value = '';
      }

      const mailInput = document.getElementById('email');
      if (mailInput) {
        mailInput.value = '';
      }

      const subjectInput = document.getElementById('assunto');
      if (subjectInput) {
        subjectInput.value = '';
      }

      const messageInput = document.getElementById('mensagem');
      if (messageInput) {
        messageInput.value = '';
      }
    };

    const requestData = {
      subject: subject,
      message: `Nome: ${name}\n E-mail: ${mail}\n Mensagem: ${message}`,
      email: String(process.env.REACT_APP_MAIL_API_AUTHORITY_SENDER),
      destination:
        process.env.REACT_APP_MAIL_DESTINATION ||
        "antonioandriettineto@gmail.com",
    };

    const url = `${process.env.REACT_APP_MAIL_API_URL}send-email`;

    const requestConfig = {
      "headers": {
        "app-secret": String(process.env.REACT_APP_MAIL_API_SECRET),
        "app-key": String(process.env.REACT_APP_MAIL_API_KEY),
        "accept": "application/json",
        "Content-Type": "application/json"
      }
    };

    try {
      const res = await axios.post(url, requestData, requestConfig);
      window.alert("Agradecemos seu contato, conversamos em breve!");
      cleanForm();
    } catch (error) {
      window.alert("Ocorreu um erro ao enviar o formulário.");
    }
  } // Empty dependency array ensures the effect runs only once after mount

  return (
    <section id="le" className="pt">
      <div className="container-inner">
        <div className="titulo-wrapper">
          <h2 className="title lang-pt">Law Enforcement</h2>
       </div>
        <div className="section-content">
          <p>{lang === "pt" ? "A nossa empresa está comprometida com rigorosas políticas anti lavagem de dinheiro e antifraude. Buscamos garantir a integridade e segurança das transações, adotando medidas robustas para prevenir atividades ilícitas. Para acelerar e facilitar os processos de investigação, fornecemos este canal para comunicação por parte de autoridades." : null}
            {lang === "es" ? "Nuestra empresa está comprometida con estrictas políticas contra el lavado de dinero y el fraude. Buscamos garantizar la integridad y seguridad de las transacciones, adoptando medidas robustas para prevenir actividades ilícitas. Para agilizar y facilitar los procesos de investigación, brindamos este canal de comunicación por parte de las autoridades." : null}
            {lang === "en" ? "Our company is committed to strict anti-money laundering and anti-fraud policies. We seek to guarantee the integrity and security of transactions, adopting robust measures to prevent illicit activities. To speed up and facilitate investigation processes, we provide this channel for communication by authorities." : null}</p>
        <br /><br />
          <div className="form-wrapper">
            <div className="wpcf7 js" id="wpcf7-f6-o1" lang="pt-BR" dir="ltr">
              <div className="screen-reader-response">
                <p role="status" aria-live="polite" aria-atomic="true" />
                <ul />
              </div>
              <form>
                <div style={{ display: "none" }}>
                  <input type="hidden" name="_wpcf7" defaultValue={6} />
                  <input
                    type="hidden"
                    name="_wpcf7_version"
                    defaultValue="5.7.1"
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_locale"
                    defaultValue="pt_BR"
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_unit_tag"
                    defaultValue="wpcf7-f6-o1"
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_container_post"
                    defaultValue={0}
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_posted_data_hash"
                    defaultValue=""
                  />
                  <input
                    type="hidden"
                    name="_wpcf7_recaptcha_response"
                    defaultValue="03ADUVZwBk_PBKrjlG9Luzfnelve8apRbggoXDJcpiJQNiJuyBOd6C66y6YzpcG2BefqfcJLMVtORaPb3G9EXQX53QoPFUE3hlVJzj-SP4C_w7R2NsJCZxNYTnvTZiHE_c5GQQuu6gk1fCgkHoE2gQmA_PV27l2_gqzYVe1L7wZcTY2GOUkHrkIAPdwpXS3KJoilDaHzdHIw9XXcAZ0Cu6LnIpifnnrCG5RXtCI-eZqlhD705cNvc2Jyt_DT3MesNdk1r1AUfYMa7WZAhCxBLN-kLACzKA8vBweZ3hXEoD6eW6syRR8fAdnVnLhLFjwWc5bo3ZQPc-PK-TQLf_cGYmmUfy6YXwRkF-R-4uOw8op0lTw5vlYg_XNHBnBpKyhWsQcPw_BbK_hNIIMd10oPxII0bUaFMj4Fc2jgok_4EQ6jLbwJGHd9pVv-Ir_OoUCNfd8rKCotdxSNcVsnO5_aLQ8ED6Cj9oTy0fWK4hpKNTvrZ0R7PeaMlbD_tTk_-cxKWc3URYBpxrRHkVrZ2eToxTmtq5uq9G9MJZYUgZxBKOQ4gjDRvIkco8y7Ndw2HcIu16SbO9zqJBSipBIO5CHwOWW4b8U43r-wmjrw"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="nome">
                    <span className="lang-pt">{lang === "pt" ? "NOME" : null}
                      {lang === "es" ? "NOMBRE" : null}
                      {lang === "en" ? "NAME" : null}</span>
                    :
                  </label>
                  <span className="wpcf7-form-control-wrap" data-name="nome">
                    <input
                      size={40}
                      className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ${shouldShake ? 'shake-effect' : ''}`}
                      id="nome"
                      aria-required="true"
                      aria-invalid="false"
                      defaultValue=""
                      type="text"
                      name="nome"
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </span>
                </div>
                <div className="input-group">
                  <label htmlFor="email">EMAIL:</label>
                  <span className="wpcf7-form-control-wrap" data-name="email">
                    <input
                      size={40}
                      className={`wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email ${shouldShake ? 'shake-effect' : ''}`}
                      id="email"
                      aria-required="true"
                      aria-invalid="false"
                      defaultValue=""
                      type="email"
                      name="email"
                      required
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </span>
                </div>
                <div className="input-group">
                  <label htmlFor="assunto">
                    <span className="lang-pt">{lang === "pt" ? "ASSUNTO" : null}
                      {lang === "es" ? "SUJETO" : null}
                      {lang === "en" ? "SUBJECT" : null}</span>:
                  </label>
                  <span className="wpcf7-form-control-wrap" data-name="assunto">
                    <input
                      size={40}
                      className={`wpcf7-form-control wpcf7-text wpcf7-validates-as-required ${shouldShake ? 'shake-effect' : ''}`}
                      id="assunto"
                      aria-required="true"
                      aria-invalid="false"
                      defaultValue=""
                      type="text"
                      name="assunto"
                      required
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </span>
                </div>
                <div className="input-group">
                  <label htmlFor="">
                    <span className="lang-pt">{lang === "pt" ? "MENSAGEM" : null}
                      {lang === "es" ? "MENSAJE" : null}
                      {lang === "en" ? "MESSAGE" : null}</span>:
                  </label>
                  <span
                    className="wpcf7-form-control-wrap"
                    data-name="mensagem"
                  >
                    <textarea
                      cols={30}
                      rows={5}
                      className={`wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required ${shouldShake ? 'shake-effect' : ''}`}
                      id="mensagem"
                      aria-required="true"
                      aria-invalid="false"
                      name="mensagem"
                      defaultValue={""}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </span>
                </div>
                <div className="button-wrapper">
                  <button id="primary" type="button" onClick={SendMessage}>
                    <span className="lang-pt">{lang === "pt" ? "Enviar" : null}
                      {lang === "es" ? "Enviar" : null}
                      {lang === "en" ? "Send" : null}</span>
                    <span className="lang-en">Send</span>
                  </button>
                  <div className="contactSession">
                    <div className="contact">
                      <p style={{ fontWeight: 600 }} className="lang-pt">
                        {lang === "pt" ? "Telefone/WhatsApp" : null}
                        {lang === "es" ? "Teléfono/WhatsApp" : null}
                        {lang === "en" ? "Phone/WhatsApp" : null}:
                      </p>
                      <br />
                      <a href={`https://wa.me/${process.env.REACT_APP_WHATSAPP.replace(' ', '').replace(' ', '').replace('-', '').replace(')', '').replace('(', '').replace('+', '')}`} className="clickable"> {process.env.REACT_APP_WHATSAPP}</a>

                    </div>


                    <div className="contact">
                      <p style={{ fontWeight: 600 }} className="lang-pt">
                        E-mail:
                      </p>
                      <br />
                      <a href={`mailto:${process.env.REACT_APP_MAIL}`} className="clickable">{process.env.REACT_APP_MAIL}</a>

                    </div>
                  </div>

                </div>
                <div className="wpcf7-response-output" aria-hidden="true" />
                <input
                  type="hidden"
                  name="DTSUCQrE_Z"
                  defaultValue="ie6paTGz8g4@."
                />
                <input
                  type="hidden"
                  name="JUtZXSKlW"
                  defaultValue="f7W[iazvj"
                />
                <input
                  type="hidden"
                  name="FlbUtdAyMOkL"
                  defaultValue="CwZHD8@dkLY"
                />
                <input
                  type="hidden"
                  name="XeIrWhLkxOVmf-d"
                  defaultValue="lJVHphka]y"
                />
              </form>
            </div>
          </div>
          <div className="detalhe-bottom"></div>
        </div>
      </div>
    </section>
  );
}

export default LawEnforcement;
