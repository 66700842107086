import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function NavBar(
  currentDirection,
  setCurrentDirection,
  setShowViewport
) {
  let [searchParams, setSearchParams] = useSearchParams();

  const [lang,] = useState(
    searchParams.get("lang") || "pt"
  );

  const [statusNav, setStatusNav] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(lang);
  const [showAvailableLangs, setShowAvailableLangs] = useState(false);


  const onClickShowLangs = () => {
    setShowAvailableLangs(!showAvailableLangs);
  };

  const goToHome = () => {
    setCurrentDirection("home");
    setShowViewport(true);
    setStatusNav(!statusNav);
    const detailsSection = document.getElementById("welcome");
    if (detailsSection) {
      const yOffset = detailsSection.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: yOffset, behavior: 'smooth' });
    }
;
    searchParams.set("direction", "home");
    setSearchParams(searchParams);
    window.location.href = window.location.href;
  };

  const goToAbout = () => {
    setCurrentDirection("home");
    setShowViewport(true);
    setStatusNav(!statusNav);
    const aboutSession = document.getElementById("about");
    if (aboutSession) {
      const yOffset = aboutSession.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: yOffset, behavior: 'smooth' });
    }
    searchParams.set("direction", "about");
    setSearchParams(searchParams);
    window.location.href = window.location.href;
    
  };

  const goToContact = () => {
    setCurrentDirection("contact");
    setShowViewport(true);
    setStatusNav(!statusNav);
    searchParams.set("direction", "contact");
    setSearchParams(searchParams);
    window.location.href = window.location.href;
  };

  const goToLawEnforcement = () => {
    setCurrentDirection("le");
    setShowViewport(true);
    setStatusNav(!statusNav);
    searchParams.set("direction", "le");
    setSearchParams(searchParams);
    window.location.href = window.location.href;

  };

  const goToTerms = () => {
    setCurrentDirection("policies");
    setShowViewport(true);
    setStatusNav(!statusNav);
    searchParams.set("direction", "policies");
    setSearchParams(searchParams);
    window.location.href = window.location.href;

  };

  const goToPrivacy = () => {
    setCurrentDirection("privacy");
    setShowViewport(true);
    setStatusNav(!statusNav);
    searchParams.set("direction", "privacy");
    setSearchParams(searchParams);
    window.location.href = window.location.href;
  };

  const showNav = () => {
    setStatusNav(!statusNav);
    if (currentDirection !== "home") {
      setShowViewport(statusNav);
    } else if (statusNav) {
      setShowViewport(true);
    }
  };

  const translatePage = (language) => {
    searchParams.set("lang", language);
    setSearchParams(searchParams);
    window.location.href = window.location.href;
  };

  return (
    <nav id="nav-bar" className={statusNav ? "active" : ""}>

      <div className="nav-bar-wrapper">
        <header className="logo-wrapper">
          <img
            src={process.env.PUBLIC_URL + "/logo.webp"}
            className="App-logo"
            alt="logo"
            onClick={goToHome}
          />
        </header>

        <div  className="nav-bar-wrapper-links">
          <div id="nav-mobile" style={{ alignItems: "center",  marginLeft: "auto", marginRight: "0"}} >

            <div id="lang-mobile"  className="language-selector" onClick={onClickShowLangs}>

              <div className="drop-down" style={{ display: showAvailableLangs ? "" : "none" }}>

                <div className="drop-down-items">

                  <img className="country-flag" src="/br.png" alt="pt-br" onClick={() => {
                    setSelectedFlag("pt");
                    translatePage("pt");
                  }} />

                  <img className="country-flag" src="/usa.png" alt="en" onClick={() => {
                    setSelectedFlag("en");
                    translatePage("en");
                  }} />
                  <img className="country-flag" src="/spain.svg" alt="es" onClick={() => {
                    setSelectedFlag("es");
                    translatePage("es");
                  }} />
                </div>

              </div>

              <div style={{ display: "flex", height: "1rem", gap: "0.666rem" }}>

                {selectedFlag === "pt" ? <img className="selected-flag" src="/br.png" alt="pt-br" /> : <></>
                }

                {selectedFlag === "es" ? <img className="selected-flag" src="/spain.svg" alt="pt-br" /> : <></>
                }

                {selectedFlag === "en" ? <img className="selected-flag" src="/usa.png" alt="pt-br" /> : <></>
                }


                <img src="/arrow-d.png" style={{ scale: "45%" }} alt="pt-br" />

              </div>

            </div>
            <button
              id="btn-mobile"
              aria-label="Abrir menu"
              aria-haspopup="true"
              aria-controls="menu"
              aria-expanded="false"
              onClick={showNav}
            >

              <span id="hamburger"></span>
            </button>

          </div>

          <div id="nav-pc" style={{  alignItems: "center", minWidth: "75vw", justifyContent: "space-between"}}>
            <ul className="nav">
              <li
                className={`link ${currentDirection === "about" ? "active" : ""}`}
                data-section="about"
                id="1"
              >

                <p onClick={goToAbout} href="#">
                  {lang === "pt" ? "Quem Somos" : null}
                  {lang === "es" ? "Quienes somos" : null}
                  {lang === "en" ? "About" : null}
                </p>
              </li>
              <li
                className={`link ${currentDirection === "contact" ? "active" : ""
                  }`}
                data-section="contato"
                id="2"
              >
                <p onClick={goToContact} href="#">
                  <span className="lang-pt">
                    {lang === "pt" ? "Contato" : null}
                    {lang === "es" ? "Contacto" : null}
                    {lang === "en" ? "Learn More" : null}
                  </span>
                </p>
              </li>
              <li
                className={`link ${currentDirection === "le" ? "active" : ""
                  }`}
                data-section="contato"
                id="3"
              >
                <p onClick={goToLawEnforcement}>
                  <span className="lang-pt">Law Enforcement</span>
                </p>
              </li>

              <li
                className={`link ${currentDirection === "policies" ? "active" : ""
                  }`}
                id="4"
              >
                <p onClick={goToTerms}>
                  <span className="lang-pt">
                    {lang === "pt" ? "Políticas de Uso" : null}
                    {lang === "es" ? "Políticas de Uso" : null}
                    {lang === "en" ? "Usage Policies" : null}
                  </span>
                </p>
              </li>

              <li
                className={`link ${currentDirection === "privacy" ? "active" : ""
                  }`}
                id="5"
              >
                <p onClick={goToPrivacy}>
                  <span className="lang-pt">

                    {lang === "pt" ? "Termo de Privacidade" : null}
                    {lang === "es" ? "Políticas de Privacidad" : null}
                    {lang === "en" ? "Privacy" : null}
                  </span>
                </p>
              </li>

            </ul>


            <div id="lang-pc" style={{ marginTop: "1rem", marginRight: "auto", marginRight: "0"}} className="language-selector" onClick={onClickShowLangs}>

              <div className="drop-down" style={{ display: showAvailableLangs ? "" : "none" }}>

                <div className="drop-down-items">

                  <img className="country-flag" src="/br.png" alt="pt-br" onClick={() => {
                    setSelectedFlag("pt");
                    translatePage("pt");
                  }} />

                  <img className="country-flag" src="/usa.png" alt="en" onClick={() => {
                    setSelectedFlag("en");
                    translatePage("en");
                  }} />
                  <img className="country-flag" src="/spain.svg" alt="es" onClick={() => {
                    setSelectedFlag("es");
                    translatePage("es");
                  }} />
                </div>

              </div>

              <div style={{ display: "flex", height: "1rem", gap: "0.666rem" }}>

                {selectedFlag === "pt" ? <img className="selected-flag" src="/br.png" alt="pt-br" /> : <></>
                }

                {selectedFlag === "es" ? <img className="selected-flag" src="/spain.svg" alt="pt-br" /> : <></>
                }

                {selectedFlag === "en" ? <img className="selected-flag" src="/usa.png" alt="pt-br" /> : <></>
                }


                <img src="/arrow-d.png" style={{ scale: "45%" }} alt="pt-br" />

              </div>

            </div>
          </div>
        </div>


      </div>
    </nav>
  );
}

export default NavBar;
