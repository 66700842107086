import { useState } from "react";
import { useSearchParams } from "react-router-dom";


function Terms() {

    let [searchParams, setSearchParams] = useSearchParams();

    const [lang,] = useState(
        searchParams.get("lang") || "pt"
    );


    const [currentDirection,] = useState(
        searchParams.get("direction") || "home"
    );

    var title = "";
    var pdfPath = '';

    if (currentDirection === 'privacy') {
        if (lang === "pt") {
            title = "Termo de Privacidade";
            pdfPath = process.env.PUBLIC_URL + '/privacy-pt.pdf';
        } else if (lang === "es") {
            title = "Término de privacidad";
            pdfPath = process.env.PUBLIC_URL + '/privacy-es.pdf';
        } else if (lang === "en") {
            title = "Privacy Policies";
            pdfPath = process.env.PUBLIC_URL + '/privacy-en.pdf';
        };

        
    } else if (currentDirection === 'policies') {
        if (lang === "pt") {
            title = "Políticas de Uso";
            pdfPath = process.env.PUBLIC_URL + '/policies-pt.pdf';
        } else if (lang === "es") {
            title = "Políticas de Uso";
            pdfPath = process.env.PUBLIC_URL + '/policies-es.pdf';
        } else if (lang === "en") {
            title = "Usage Policies";
            pdfPath = process.env.PUBLIC_URL + '/policies-en.pdf';
        };

        
    }


    return (
        <div className="terms">
            <h1>{title}</h1>
            <object data={pdfPath} type="application/pdf" className="pdf-viewer">
            </object>
        </div>
    );

}


export default Terms;
