import "../style/Footer.scss";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

function Footer() {

  let [searchParams, setSearchParams] = useSearchParams();

  const [lang,] = useState(
    searchParams.get("lang") ||"pt"
  );

  const goToLawEnforcement = () => {
    searchParams.set("direction", "le");
    setSearchParams(searchParams);
    window.location.href = window.location.href;
  };

  function goDaddy () {
    window.location.href = "https://sso.godaddy.com/?app=email&realm=pass&status=2"
  }

  return (
    <div className="footer">
      <div className="logo-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/logo.webp"}
          className="App-logo"
          alt="logo"
        />
        <p>
          CNPJ: 48.944.140/0001-44
        </p>
      </div>

      <div className="entries">
      <div className="footer-contact">
        <h1>
          {lang === "pt" ? "CONTATO" : null}
          {lang === "es" ? "CONTACTO" : null}
          {lang === "en" ? "CONTACT" : null}
        </h1>
        <p>

        {lang === "pt" ? "Fone" : null}
        {lang === "es" ? "Teléfono" : null}
        {lang === "en" ? "Phone" : null}: {process.env.REACT_APP_WHATSAPP} <br />
          E-mail: contato@gardencrypto.com.br
          
        </p>
        <button onClick={goDaddy} className="mail-btn">
        <img src="mail.webp" alt="mail-btn"/>
        </button>
      </div>

      <div className="footer-address">
        <h1>
        {lang === "pt" ? "ENDEREÇO" : null}
        {lang === "es" ? "DIRECCIÓN" : null}
        {lang === "en" ? "ADDRESS" : null}
        </h1>
        <p>
          Avenida Ephigenio Salles, N 1299 Sala 645
          <br />
          CEP: 69060-020
          <br />
          Manaus - AM
        </p>

      </div>


      <div className="footer-policies">
        <p>

        {lang === "pt" ? "A nossa empresa está comprometida com rigorosas políticas anti lavagem de dinheiro e antifraude. Buscamos garantir a integridade e segurança das transações, adotando medidas robustas para prevenir atividades ilícitas. Para acelerar e facilitar os processos de investigação, fornecemos um canal imediato para comunicação às autoridades." : null}
        {lang === "es" ? "Nuestra empresa está comprometida con estrictas políticas contra el lavado de dinero y el fraude. Buscamos garantizar la integridad y seguridad de las transacciones, adoptando medidas robustas para prevenir actividades ilícitas. Para agilizar y facilitar los procesos de investigación, brindamos un canal inmediato de comunicación a las autoridades." : null}
        {lang === "en" ? "Our company is committed to strict anti-money laundering and anti-fraud policies. We seek to guarantee the integrity and security of transactions, adopting robust measures to prevent illicit activities. To speed up and facilitate investigation processes, we provide an immediate channel for communication to authorities." : null}

        
        </p>
      <button onClick={goToLawEnforcement}>
      {lang === "pt" ? `Canal de Comunicação para Autoridades` : null}
        {lang === "es" ? `Canal de comunicación para autoridades` : null}
        {lang === "en" ? `Communication Restricted
       to Authorities` : null}

      </button>
      </div>
      </div>
    </div>
  )
}

export default Footer;