import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Viewport from "./Components/Viewport";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Whatsapp from "./Components/Whatsapp";

import "./style/App.scss";
import "./style/Nav.scss";
import "./style/Text.scss";
import "./style/Viewport.scss";
import "./style/Language.scss";
import "./style/Animation.scss";
import "./style/Layout.scss";
import "./style/Register.scss";

const showAbout = () => {
  const detailsSection = document.getElementById("about");
  if (detailsSection) {
    const yOffset =
      detailsSection.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yOffset, behavior: "smooth" });
  }
};

function LandingPage() {

  const [searchParams] = useSearchParams();

  const [showViewport, setShowViewport] = useState(
    ["options", "contact", "areas"].includes(searchParams.get("direction"))
      ? true
      : false
  );
  const [currentDirection, setCurrentDirection] = useState(
    searchParams.get("direction") || "home"
  );

  const [lang, ] = useState(
    searchParams.get("lang") || "pt"
  );

  if (currentDirection === "about") {
    showAbout();
  }
  return (
    <div className="App">
      <div className="page-background">
        {NavBar(currentDirection, setCurrentDirection, setShowViewport)}
        <div className="container">
          {Viewport(showViewport, currentDirection)}
          {Whatsapp()}
        </div>
        {}
        {Footer()}
        <div className="rights">
          
        <p>GARDEN CRYPTO® 
          {lang === "pt"? "Todos Os Direitos Reservados" : null}
          {lang === "es"? "Todos los derechos reservados": null}
          {lang === "en"? "All Rights Reserved": null}
        </p>
      </div>
      </div>
    </div>
  );
}

export default LandingPage;
