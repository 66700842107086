import Contact from "../LandingContent/Contact";
import LawEnforcement from "../LandingContent/LawEnforcement";
import Home from "../LandingContent/Home";
import Terms from "../LandingContent/Terms";
import "../style/Viewport.scss";
import "../style/Language.scss";

function LoadContent(currentDirection) {

  if (currentDirection === "home" || currentDirection === "about") {
    return <Home />;
  } else if (currentDirection === "contact") {
    return <Contact />;
  } else if (currentDirection === "le") {
    return <LawEnforcement />;
  }  else if (currentDirection === "privacy" || currentDirection === "policies") {
    return <Terms />;
  } else {
    window.location.href = "/not-found";
  }
}

function Viewport(showViewport, currentDirection) {
  return (
    <div id="viewport" className="active">
      {LoadContent(currentDirection)}
    </div>
  );
}

export default Viewport;
