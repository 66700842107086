import React from 'react';

const NotFoundPage = function() {
    return (
<section class="page_404">
	<div class="container">
		<div class="row">	
		<div class="col-sm-12 ">
		<div class="col-sm-10 col-sm-offset-1  text-center">
		<div class="four_zero_four_bg">
			<h1 class="text-center ">404</h1>
		
		
		</div>
		
		<div class="contant_box_404">
		<h3 class="h2">
		Ops, página não encontrada.
		</h3>
		
		<p>A página que você está tentando acessar, encontra-se indisponível!</p>

		<a href="/" class="link_404">Ir para página inicial</a>
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
    )
};


export default NotFoundPage;